import "./App.css";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { styled } from "@mui/material";
import React, { startTransition, useState, useEffect } from "react";
import { signIn } from "@ramper/ethereum";
// import { useCountdown } from "./Countdown";
import example from "./example.png";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
// import JSEncrypt from "jsencrypt";

function ramperLogin(key) {
  startTransition(async () => {
    // const encrypt = JSEncrypt();
    // encrypt.setPublicKey(key);
    const result = await signIn();
    // console.log("result: ", result);
    if (result.method === "ramper" && result.user) {
      const email = result.user.email;
      const token = result.user.ramperCredential?.idToken;
      if (email && token) {
        // console.log({ email, token });
        // window.open(`objekt://result?email=${email}&token=${token}`, "_blank");
        window.location.href = `objekt://result?email=${email}&token=${token}`;
      }
    } else {
      alert("Authentication didn't succeed.\nPlease try again.");
    }
  });
}

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

function App() {
  const { key } = useParams();
  const size = useWindowSize();
  const [count, setCount] = useState(5);
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((c) => c - 1);
    }, 1000);
    return () => clearInterval(interval);
  });

  const theme = createTheme({
    palette: {
      primary: {
        main: "#ffffff",
        // contrastText: yellow[500],
        contrastText: "black",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              background: "#000000",
              border: "1px solid",
              color: "#dadada",
            },
          },
        },
      },
    },
  });

  const buttonStyle = {
    width: 140,
  };

  const style = {
    backgroundColor: "black",
    // height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="App" style={style}>
        <img
          src={example}
          alt="example"
          height={size.width > size.height ? 320 : undefined}
          width={size.width > size.height ? undefined : size.width * 0.9}
        />
        <Box height={24} />
        <p style={{ color: "white" }}>
          You must click "confirm from a different device" when you receive
          confirmation mail.
        </p>
        <Box height={24} />
        <Button
          variant={count > 0 ? "outlined" : "contained"}
          style={buttonStyle}
          onClick={() => ramperLogin(key)}
          disabled={count > 0}
        >
          {count > 0 ? `Sign In (${count})` : "Sign In"}
        </Button>
      </div>
    </ThemeProvider>
  );
}

export default App;
